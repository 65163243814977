const {bolt} = require('../dist/versions.json')

if (window.location.search.indexOf('BoltSource') < 0) { //eslint-disable-line lodash/prefer-includes
    window.boltBase = `https://static.parastorage.com/services/wix-bolt/${bolt}`
}

const main = `${window.boltBase}/bolt-main/app/main-r.min.js`

const loadScript = (src, name) => new Promise(resolve => {
    const element = document.createElement('script')
    element.async = false //make sure the scripts execute in the order they are added
    element.src = src
    element.onload = () => resolve()
    document.body.appendChild(element)
}).catch(err => {
    console.log(`Failed to load script ${name}: ${err.message}`) //eslint-disable-line no-console
})

loadScript('https://static.parastorage.com/unpkg-semver/wix-recorder@^1/app.bundle.min.js')
    .catch(e => console.log(e))

loadScript(main)
    .then(() => {})
    .catch(e => console.log(e))
